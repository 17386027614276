.homeBodyContainer {
  max-width: 1280px;
  margin: 0 auto;
}

.swiperContainer {
  /* margin: 98px auto 0!important; */
  /* max-width: 1440px; */
  min-width: 200px;
  width: 100%;
  margin: 0 auto;
}
.swipeNav {
  height: 280px;
}

.swipeNav .arrowGroup {
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  padding: 20px;
}

.swipeNav .arrowLeft,
.swipeNav .arrowRight {
  width: 30px;
  height: 30px;
  border: 2px solid #fb5284;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: #fff;
  cursor: pointer;
}

.swipeNav .swipeNavImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 12px;
}

/* .lists {
  display: flex;
  flex-direction: column;
  gap: 50px;
} */

.listsItem:last-child .divider {
  display: none;
}
.divider {
  width: 95%;
  margin: 50px auto;
  border-block-start: 1px solid rgba(5, 5, 5, 0.07);
}

@media only screen and (max-width: 884px) {
  /* .hotAreaContent {
    gap: 15px;
  }

  .itemImg {
    width: 120px;
  } */
}

@media only screen and (max-width: 480px) {
  .swipeNav {
    height: 45vw;
    margin: 0px 10px !important;
  }

  .swipeNav .arrowGroup {
    padding: 20px 10px;
  }

  /* .hotArea {
    display: none;
  }

  .hotAreaContent {
    gap: 10px;
  }
  .itemImg {
    width: 100px;
  } */
}

/* @media only screen and (max-width: 500px) {
  .swipeNav {
    height: 45vw;
    margin: 0px 10px !important;
  }

  .hotAreaContent {
    gap: 10px;
  }

  .swipeNav .arrowGroup { 
    padding: 20px 10px
  }
}

@media screen and (max-width: 425px) {
  .hotArea {
    width: 100vw;
  }
  .hotAreaContent {
    margin-top: 0px;
    gap: 10px;
  }

  .itemImg {
    width: 100px;
  }
} */
