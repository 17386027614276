.hotArea {
  width: 100%;
  padding: 20px 10px 10px 10px;
  margin: 0 auto 0 auto;
  /* max-width: 1440px; */
  /* display: flex;
    align-items: center;
    gap: 20px; */
}
.hotAreaTitle {
  color: #fff;
  background-color: #7d5cff;
  border-radius: 10px;
}

.vipAreaTitle {
  color: #fff;
  background-color: #ff5caa;
  border-radius: 10px;
}

.vipAreaTitle p,
.hotAreaTitle p {
  width: 100px;
  height: 40px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.hotAreaContent {
  display: flex;
  align-items: center;
  overflow-x: scroll;
  gap: 10px;
  /* margin-top: 20px; */
  /* margin-top: 10px; */
  justify-content: space-between;
}

.hotAreaContent::-webkit-scrollbar {
  width: 10px;
}

.hotAreaContent::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #ddd;
  border: solid 7px transparent;
  border-radius: 20px;
}

.hotAreaImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
}
.contentItem {
  text-align: center;
  /* margin-right: 36px;
      display: flex;
      flex-direction: column;
      align-items: center; */
}

.contentItem img {
  vertical-align: -webkit-baseline-middle;
}

.itemImgSelected {
  border: 2px solid #7d5cff;
  border-radius: 16px;
}

.itemImgSelectedFree {
  border: 2px solid #ff5caa;
  border-radius: 16px;
}

.itemImg {
  /* width: 145px; */
  /* height: 130px; */
  /* border: 2px solid #FA528A;
      border-radius: 50%; */
  object-fit: cover;
  overflow: hidden;
}
.itemName {
  color: #000;
  font-size: 14px;
  margin-top: 8px;
  max-width: 140px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 884px) {
  .hotAreaContent {
    gap: 15px;
  }

  .itemImg {
    width: 120px;
  }
}

@media only screen and (max-width: 480px) {
  .hotArea {
    display: none;
  }

  .hotAreaContent {
    gap: 10px;
  }
  .itemImg {
    width: 100px;
  }
}
