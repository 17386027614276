.masterPostsTitle {
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
}

.masterPostsTitle .more {
  color: #fa528a;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 2px;
  cursor: pointer;
}

.masterPostsTitle .more img {
  width: 6px;
}

.postsTitle {
  font-size: 18px;
  font-weight: 500;
}

.masterPostsMeinvContainer {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  row-gap: 20px;
  column-gap: 10px;
}

.masterPostsContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 20px;
  column-gap: 10px;
}

@media only screen and (max-width: 884px) {
  .masterPostsMeinvContainer {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 20px;
    column-gap: 20px;
  }

  .masterPostsContainer {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 20px;
  }
}

@media only screen and (max-width: 480px) {
  .masterPosts {
    padding: 0px 15px;
  }
  .masterPostsMeinvContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .masterPostsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
