.categoryBodyContainer {
  max-width: 1280px;
  margin: 0 auto;
  margin-top: -20px;
}

.categoryBackSection {
  display: flex;
  align-items: center;
  gap: 15px;
  /* margin: 15px 0; */
  /* margin-bottom: 25px; */
}

.categoryPostsLoading {
  position: relative;
  width: 420px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.categoryPostsLoading img {
  width: 80%;
}

.categoryPosts {
  margin-bottom: 20px;
}

.categoryPostsTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  /* margin: 15px 0px; */
}

.categoryPostsTitle p {
  font-size: 18px;
  font-weight: 500;
}

.meinvCategoryPostsContainer {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  row-gap: 20px;
  column-gap: 10px;
}

.categoryPostsContainer {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 20px;
  column-gap: 10px;
}

.notFound {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.notFound img {
  width: 250px;
}

@media only screen and (max-width: 884px) {
  .meinvCategoryPostsContainer {
    grid-template-columns: repeat(4, 1fr);
    row-gap: 20px;
    column-gap: 20px;
  }

  .categoryPostsContainer {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 20px;
  }

  .categoryPostsLoading {
    width: 300px;
  }
}

@media only screen and (max-width: 480px) {
  .categoryBodyContainer {
    padding: 0px 15px;
  }

  .categoryPostsTitle {
    margin-bottom: 10px;
  }

  .meinvCategoryPostsContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .categoryPostsContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
