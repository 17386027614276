.picDetailBodyContainer {
  max-width: 1280px;
  margin: 20px auto;
}

/* .imageDetailContent {
      display: flex;
      align-items: flex-start;
      gap: 20px;
    } */

.picDetailActorContainer {
  flex: 4;
  margin-bottom: 50px;
}

.picDetailActor {
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  margin-bottom: 20px;
}

.picDetailRecommend {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.picDetailActorImage {
  flex: 1;
}

.actorProfile {
  display: flex;
  flex: 2;
  width: 500px;
  height: 280px;
  position: relative;
  overflow: hidden;
}

.actorProfile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.picDetailActorDesc {
  flex: 3;
}
.picDetailActorDesc .desc {
  margin: 10px 0px 30px 0px;
}

.picDetailActorDesc p {
  line-height: 20px;
}

.picDetailActorDesc .category,
.picDetailActorDesc .time {
  color: #808191;
  font-size: 12px;
}

.picDetailActorDesc .label {
  display: flex;
  align-items: center;
  background: #fb5384cc;
  width: 65px;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 10px;
  gap: 3px;
}

.picDetailActorDesc .label img {
  width: 16px;
}

.picDetailActorDesc .views {
  display: flex;
  align-items: center;
  background: #fb5384cc;
  width: 65px;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  justify-content: center;
  gap: 3px;
}

.picDetailActorDesc .views img {
  width: 16px;
}

.picDetailPreviewImage {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 25px;
  column-gap: 20px;
}

.picDetailPreviewImage img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.picDetailPreviewImageContainer {
  position: relative;
  overflow: hidden;
  border-radius: 6px;
}

.picDetailPreviewImage .prev,
.picDetailPreviewImage .next {
  width: 50px;
  opacity: 1;
}

.ImageViewer img {
  width: 50%;
}

.iCvr {
  filter: blur(6px);
  opacity: 0.5;
  z-index: -1;
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: none !important;
}

.detailRecommend {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 50px;
}

.recommendTitle {
  font-size: 18px;
  font-weight: 500;
}

.detailRecommendPostList {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  row-gap: 25px;
  column-gap: 15px;
}

.detailRecommendPostList img {
  /* max-height: 200px; */
  object-fit: contain;
}


@media only screen and (max-width: 480px) {
  .picDetailBodyContainer {
    padding: 0px 15px;
  }
  .picDetailRecommend {
    display: none;
  }

  .picDetailActor {
    display: block;
  }

  .picDetailPreviewImage {
    display: block;
  }

  .actorProfile {
    width: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 8px;
  }
  .picDetailActorDesc {
    padding: 8px;
  }
  .picDetailActorDesc .category,
  .picDetailActorDesc .time {
    font-size: 14px;
  }
  .picDetailActorDesc .desc {
    margin: 10px 0px 10px 0px;
    display: flex;
    gap: 20px;
  }

  .labelViews {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .picDetailActorDesc .label {
    margin-bottom: 0px;
  }

  .picDetailPreviewImageContainer {
    margin: 20px 0px;
  }

  .picDetailPreviewImage .prev,
  .picDetailPreviewImage .next {
    width: 40px;
  }

  .detailRecommendPostList {
    grid-template-columns: repeat(2, 1fr);
  }
}
