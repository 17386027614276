* {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  margin: 0;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  -webkit-user-drag: none;
}

.bodyContainer {
  margin-top: 100px;
  min-height: 550px;
  position: relative;
  padding: 0 20px;
}


img.iCvr {
  filter: blur(3px);
  opacity: 0.5;
  z-index: -1;
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

@media only screen and (max-width: 884px) {
  .bodyContainer {
    margin-top: 70px;
  }
}

@media only screen and (max-width: 480px) {
  .bodyContainer {
    margin-top: 75px;
    padding: 0;
  }
}

/* ::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 10px 10px #ddd;
  border: solid 7px transparent;
} */