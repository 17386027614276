.meinvChapterBodyContainer {
  max-width: 1280px;
  margin: 20px auto;
}

.meinvChapterActorContainer {
  flex: 4;
  margin-bottom: 50px;
}

.meinvChapterActor {
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  margin-bottom: 50px;
}

.actorProfile {
  display: flex;
  flex: 2;
  width: 500px;
  height: 280px;
  position: relative;
}

.actorProfile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.meinvChapterActorDesc {
  flex: 3;
}
.meinvChapterActorDesc .desc {
  margin: 10px 0px 30px 0px;
}

.meinvChapterActorDesc p {
  line-height: 20px;
}

.meinvChapterActorDesc .category,
.meinvChapterActorDesc .time {
  color: #808191;
  font-size: 12px;
}

.meinvChapterActorDesc .label {
  display: flex;
  align-items: center;
  background: #fb5384cc;
  width: 65px;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 10px;
  gap: 3px;
}

.meinvChapterActorDesc .label img {
  width: 16px;
}

.meinvChapterActorDesc .views {
  display: flex;
  align-items: center;
  background: #fb5384cc;
  width: 65px;
  color: #fff;
  border-radius: 50px;
  font-size: 14px;
  justify-content: center;
  gap: 3px;
}

.meinvChapterActorDesc .views img {
  width: 16px;
}

.meinvChapterPosts {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  row-gap: 25px;
  column-gap: 15px;
  margin-bottom: 20px;
}

.iCvr {
  filter: blur(3px);
  opacity: 0.5;
  z-index: -1;
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}

@media only screen and (max-width: 884px) {
  .meinvChapterPosts {
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 20px;
  }
  
}

@media only screen and (max-width: 480px) {
  .meinvChapterBodyContainer {
    padding: 0px 15px;
  }

  .meinvChapterActor {
    display: block;
  }

  .actorProfile {
    width: 100%;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 8px;
  }

  .meinvChapterActorDesc {
    padding: 8px;
  }
  .meinvChapterActorDesc .category,
  .meinvChapterActorDesc .time {
    font-size: 14px;
  }
  .meinvChapterActorDesc .desc {
    margin: 10px 0px 10px 0px;
    display: flex;
    gap: 20px;
  }

  .labelViews {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .meinvChapterActorDesc .label {
    margin-bottom: 0px;
  }

  .meinvChapterPosts {
    grid-template-columns: repeat(2, 1fr);
  }
}
