.backButton {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #bfbfbf;
  /* margin: 10px 0px 20px 0px; */
  margin-bottom: 20px;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  transition: background-color 0.2s, transform 0.2s;
}

.backButton:hover {
  background-color: #e4e4e48e;
}

.backButton img {
  width: 65%;
}

@media only screen and (max-width: 480px) {
  .backButton {
    width: 35px;
    height: 35px;
    margin: 10px 0px 20px 0px;
  }
}
