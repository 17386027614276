.headerSection {
  position: relative;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-self: center;
}

.header {
  /* background: rgba(0, 0, 0, 0.3); */
  background: #fff;
  box-shadow: 0px 4px 4px 0px #dedede40;
  /* box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12); */
  position: fixed;
  top: 0;
  z-index: 100;
  height: 72px;
  width: 100%;
  color: #fff;
  font-weight: 700;
  line-height: 30px;
}

.scroll {
  backdrop-filter: saturate(180%) blur(20px) !important;
  background: rgba(0, 0, 0, 0.8);
  border-bottom-color: hsla(0, 0%, 100%, 0.04) !important;
  border-bottom: 1px solid transparent;
  box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14),
    0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;
}

.headerCard {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  height: 40px;
  max-width: 1300px;
  width: 1280px;
  margin: 0 auto;
}

.headerLogo {
  display: flex;
  gap: 10px;
  align-items: center;
}

.headerMenuLogo {
  position: relative;
  width: 55%;
}
.headerMenuShow {
  /* width: max-content; */
  background: #fff;
  padding: 0px 15px;
  display: flex;
  gap: 20px;
}

.headerMenuShow a {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.headerMenuShow img {
  width: 100%;
}

.headerMenuMargin {
  margin-left: 2px;
}

.arrowIconImg {
  margin-top: 5px;
}

.headerMenuChild {
  position: absolute;
  width: 100%;
  background: #fff;
  padding: 3px 15px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
}

.headerMenuChild ul li:not(:last-child) {
  border-bottom: 1px solid #dedede85;
}

.otherMenu img {
  width: 100%;
  min-height: 57px;
  display: block;
  margin: 0 auto;
  padding: 15px 0px 15px 0px;
}

.otherMenuMain img {
  margin-left: 2px;
}

.headerHomePage {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: auto;
}

.homepage {
  display: flex;
  align-items: center;
  gap: 5px;
}

.homeLink {
  color: white;
  outline: 0 none;
  transition: all 0.2s linear;
  text-decoration: none;
}

.logoDomain {
  color: #ff5cac;
}

.headerMenuList {
  display: flex;
  align-items: center;
  width: 502px;
}

.headerMenuCard {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
  position: relative;
  height: 40px;
  padding: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.headerMenuDropdownArrow {
  margin-top: 25px;
}

.headerMenuDropdownList {
  position: absolute;
  top: 31px;
  width: 100%;
  background: #333;
  padding: 14px 0px 14px 0px;
  text-align: center;
  width: 95px;
  left: 0px;
}

.headerLoginRegister {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  gap: 10px;
}

.headerLoginRegisterContainer {
  display: flex;
  align-items: center;
  gap: 10px;
}

.loginBtn,
.registerBtn {
  color: white;
  outline: 0 none;
  transition: all 0.2s linear;
  text-decoration: none;
  font-size: 14px;
}
.dropd {
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #c6c6c6;
}

.dropd:hover {
  transform: rotate(180deg);
}

.bl {
  background-color: #333;
}

.mobileHeaderSection {
  display: none;
}

@media only screen and (max-width: 884px) {
  .headerCard {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 480px) {
  .logoDomain {
    display: none;
  }

  .headerMenuList {
    display: none;
  }

  .headerCard {
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 5px;
  }

  .headerCard a {
    display: flex;
    align-items: baseline;
    width: 45%;
    margin-left: 5px;
  }

  .headerCard a img {
    width: 100%;
  }

  .headerSection {
    display: none;
  }

  .mobileHeaderSection {
    display: block;
  }

  .mobileHeaderContainer {
    position: relative;
  }

  .mobileHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    /* box-shadow: 0px 4px 4px 0px #dedede40; */
    position: fixed;
    top: 0;
    z-index: 100;
    height: 60px;
    width: 100%;
    color: #fff;
    font-weight: 700;
    /* line-height: 30px; */
  }

  .mobileHeaderShadow {
    box-shadow: 0px 4px 4px 0px #dedede40;
  }

  .mobileChildHeader {
    width: 100%;
    position: fixed;
    z-index: 10;
    background: #fff;
    top: 60px;
    box-shadow: 0px 4px 4px 0px #dedede40;
  }

  .mobileChildItem {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 15px 0px;
    border-top: 1px solid #dedede;
  }

  .mobileChildItem a {
    width: 45%;
  }

  .mobileChildItem img {
    width: 90%;
  }

  .hamburgerSlide {
    position: absolute;
    top: 55%;
    left: 5%;
    transform: translate(-5%, -50%);
  }

  .closeSidemenu {
    position: absolute;
    top: 55%;
    left: 5%;
    transform: translate(-5%, -50%);
  }

  .sidemenuHeader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 50px;
    width: 100%;
  }

  .sidemenuCategories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 20px;
    width: 100%;
  }

  .sidemenuCategoriesImage {
    width: 85%;
    margin: 0 auto;
  }

  .mobileSlideContent {
    opacity: 0;
    position: fixed;
    top: 0;
    left: -450px;
    min-width: 300px;
    max-width: 50vw;
    width: 100vw;
    height: 100vh;
    z-index: -1;
    overflow: scroll;
    background: #fff;
    transition: all 0.25s;
  }

  .mobileSlideContent.open {
    z-index: 10002;
    opacity: 1;
    left: 0;
  }

  .overlay {
    opacity: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    z-index: -1;
    transition: all 0.35s;
  }

  .overlay.open {
    opacity: 0.5;
    z-index: 10001;
  }
}
