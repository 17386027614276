:root {
    --app-min-width: 1398px;
    --inner-min-width: 1100px;
    --color1: #90684c;
    --colorbg: #fafafa;

    --theme_oringinal: linear-gradient(130deg, #fe8bb5, #d5c5dd);
    --theme_hover_original: #e77996;
    --theme_orange: linear-gradient(323deg,#f99d4f,#fbcea7);
    --theme_hover_orange: #f99d4f;
    --theme_blue: linear-gradient(323deg,#5eb8bc,#62dce0);
    --theme_hover_blue: #5eb8bc;
}

html,
body, body>div:first-child, div#__next, div#__next>div {
    height: 100%;
}

body {
    background-color: var(--colorbg);
}

.c1 {
    color: var(--color1);
}
.fl {
    display: flex;
}
.grid {
    display: grid;
}

.align_center {
    align-items: center;
}

.justify_center {
    justify-content: center;
}
.justify_end {
    justify-content: end;
}
.point {
    cursor: pointer;
}
.app_min_width {
    width: var(--app-min-width);
}
.gap5 {
    gap: 5px;
}
.gap10 {
    gap: 10px;
}
.gap15 {
    gap: 15px;
}
.gap20 {
    gap: 20px;
}
.gap25 {
    gap: 25px;
}
.gap30 {
    gap: 30px;
}
.gap40 {
    gap: 40px;
}

.fz12 {
    font-size: 12px;
}
.fz13 {
    font-size: 13px;
}
.fz14 {
    font-size: 14px;
}
.fz15 {
    font-size: 15px;
}
.fz16 {
    font-size: 16px;
}
.fz18 {
    font-size: 18px;
}
.mauto {
    margin: auto;
}
.m0auto {
    margin: 0 auto;
}
.a_w {
    color: white;
    outline: 0 none;
    transition: all .2s linear;
    text-decoration: none;
}
.a_w:visited {
    color: white;
}
.a_b {
    color: #333;
    outline: 0 none;
    transition: all .2s linear;
    text-decoration: none;
}
.a_b:visited {
    color: #333;
}
input {
    outline: none;
    background: transparent;
    border: 0;
    width: 100%;
    font-size: 15px;
}
input::placeholder {
    padding: 1px 2px;
    font-size: 15px;
}
.bgfa {
    background-color: #fafafa;
}

.btn_sty1 {
    border-radius: 41px;
    color: #fff;
    font-size: 20px;
    line-height: 50px;
    background: var(--theme_orange);
    width: 100%;
    border: none;
    cursor: pointer;
    height: 52px;
}
.theme_orange {
    background: var(--theme_orange);
}
.theme_blue {
    background: var(--theme_blue);
}
.theme_oringinal {
    background: var(--theme_oringinal);
}

.btn_sty2 {
    background: linear-gradient(#f08bad,#ff337c);
    color: #fff;
    border-radius: 8px;
    padding: 5px;
    width: 84%;
    border: none;
    cursor: pointer;
}
.btn_sty3 {
    background-color: #ab99cc;
    color: #fff;
    border-radius: 6px;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    width: 100px;
    border: none;
}
.btn_sty4 {
    background-color: #6adbdc;
    color: #fff;
    border-radius: 6px;
    font-size: 14px;
    height: 36px;
    line-height: 36px;
    width: 100px;
    border: none;
}
.btn_sty5 {
    background-color: #d2bda2;
    border-radius: 6px;
    color: #fff;
    cursor: pointer;
    font-size: 14px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    max-width: 945px;
    min-width: 249px;
    border: none;
}
.btn_sty6 {
    color: #fff;
    font-size: 20px;
    line-height: 50px;
    background: linear-gradient(#f08bad,#ff337c);
    width: 100%;
    border: none;
    cursor: pointer;
    text-align: center;
}
.fw100 {
    font-weight: 100;
}
.fw200 {
    font-weight: 200;
}
.fw300 {
    font-weight: 300;
}
.fw400 {
    font-weight: 400;
}
.fw500 {
    font-weight: 500;
}

.d_none {
    text-decoration: none;
}
.relative {
    position: relative;
}
.abs {
    position: absolute;
}
.mt20 {
    margin-top: 20px;
}
.mt5 {
    margin-top: 5px;
}
.mt10 {
    margin-top: 10px;
}
.mt20 {
    margin-top: 20px;
}
.mt40 {
    margin-top: 40px;
}
.mb20 {
    margin-bottom: 20px;
}
.mt120 {
    margin-top: 120px;
}
.common_modal {
    width: 393px !important;
    height: 180px !important;
}
.vip_modal {
    max-width: 320px;
    min-height: 320px;
    padding: 0px !important;
}
.top0 {
    top: 0px;
}
.fl1 {
    flex: 1;
    text-align: center;
}
.text_center {
    text-align: center;
}
.mlauto {
    margin-left: auto;
}
.justify_between {
    justify-content: space-between;
}
.w25 {
    width: 25px;
}
.w100 {
    width: 100%;
}
.of_cover {
    object-fit: cover;
}
.inner_min {
    max-width: var(--inner-min-width);
    min-width: 300px;
    margin: 0 auto;
}
.mt9 {
    margin-top: 9px;
}
.text_line {
    text-decoration: line-through
}
.con_lay {
    display: grid;
    gap: 40px;
    padding: 20px;
}
.red {
    color: red;
    font-size: 14px;
}
.bold {
    font-weight: bold;
    font-size: 20px;
}
.kf{
    color: #21b2f5;
    cursor: pointer;
}
.small_b {
    font-size: 13px;
}
.small_g {
    font-size: 13px;
    color: #777777;;
}
select {
    background: #f1f2f4;
    border: none;
    border-radius: 6px;
    margin-right: 4px;
    outline: none;
    padding: 4px;
    width: 80px;
    font-size: 12px;
    height: 25px;
}
.mask {
    background: rgba(0,0,0,.7);
    bottom: 0;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11000;
}

.s_h {
    color: #333;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    margin: 0;
    padding-left: 10px;
    margin-top: 10px;
}

.fl_wrap {
    flex-wrap: wrap;
}
.mw1100 {
    width: 1440px;
    margin: auto;
}
.mh700 {
    min-height: 700px;
}
.to {
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    white-space: nowrap;
    margin-top: 5px;
}
.bl {
    color: blue;
}

.main {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.main>div:nth-child(1) {
    line-height: 40px;
}
.main>div:nth-child(2) {
    font-size: 14px;
    color: #E77996;
    cursor: pointer;
}


@media only screen and (max-width: 600px) {
    .plr{
        padding-left: 10px;
        padding-right: 10px;
    }
    .con_lay {
        grid-gap: 20px;
        gap: 20px;
        padding-left: 10px;
        padding-right: 10px;
        padding-bottom: 10px;
        padding-top: 0px;
    }
    .mw1100 {
        width: 100%;
    }
    .hideh {
        display: none;
    }
    .main {
        padding-left: 10px;
        padding-right: 10px;
    }
    .sh_ico img{
        width: 80% !important;
        object-fit: fill !important;
    }
    .sh_ico div {
        text-align: center;
    }
    .sh_ico {
        align-items: center;
    }
    .sdw {
        height: 400px !important;
    }
    .header_orange {
        /* background: var(--theme_orange); */
        background: #fff;
    }
    .header_blue {
        background: var(--theme_blue);
    }
    .header_original {
        background: var(--theme_oringinal);
    }
}
.mt20 {
    margin-top: 20px;
}
video {
    width: 100%;
    height: 100%;
}
audio {
    width: 300px;
    height: 54px;
}
#qrcode_img_hide {
    display: none;
}
.br20 {
    border-radius: 20px;
}
.lt {
    border-top: 1px solid #d1d1d1;
}
.lb {
    border-bottom: 1px solid #d1d1d1;
}
.mb10 {
    margin-bottom: 10px;
}
.pad10 {
    padding: 10px;
}
img {
    object-fit: cover;
}
.mt80 {
    margin-top: 80px;
}
.ht {
    color: #333;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}
.text_over {
    text-overflow: hidden;
    overflow: hidden;
}
.mr10 {
    margin-right: 10px;
}
.h800 {
    min-height: 800px;
}
.aimg {
    width: 100%;
    max-height: 56px;
    height: 56px;
    object-fit: contain !important;
}
.di {
    /* height: 200px; */
    flex: 1;
}
.di img {
    object-fit: contain;
}
.h100 {
    height: 100%;
}
.y_scroll {
    overflow-y: scroll;
}
.vjs_video_3-dimensions {
    width: 100% !important;
    height: 400px;
}

.fill {
    object-fit: cover;
}
.fill1 {
    object-fit: fill;
    height: 100%;
    width: 100%;
}
.i_cvr {
    filter: blur(5px);
    opacity: 0.8;
    z-index: -1;
    position: absolute;
    top: 0px;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover !important;
}
.sdw {
    height: 400px;
}
.tese_sdw {
    height: 600px;
}
.sh_ico img{
    width: 200px;
    object-fit: contain;
}
.wauto {
    width: auto;
    margin-right: 10px;
}