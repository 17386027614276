.postCard {
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  cursor: pointer;
}

.meinvPostProfile {
  position: relative;
  height: 180px;
}

.postProfile {
  position: relative;
  /* height: 300px; */
  height: 200px;
}

.meinvPostProfile img,
.postProfile img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.postDesc {
  padding: 8px;
  font-size: 13px;
}

.postDescTitle {
  min-height: 30px;
}

.postViews {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 8px 0px 0px 0px;
}

.postViewLabelList {
  display: flex;
  gap: 5px;
}

.postViewLabel {
  background: rgba(251, 82, 132, 0.6);
  padding: 0px 8px;
  border-radius: 50px;
  color: #fff;
  font-size: 14px;
}

.postViewLabel p {
  font-size: 12px;
}

.postViewLabelPurple {
  background-color: #7d5cff;
  padding: 2px 8px;
  border-radius: 50px;
  color: #fff;
  font-size: 11px;
}

.postViewCheck {
  display: flex;
  align-items: center;
  gap: 5px;
}

.postViewCheck img {
  width: 18px;
}

.postViewCheck p {
  font-size: 14px;
  line-height: 16px;
  font-weight: 100;
  color: #808191;
}

.postViewDate {
  color: #999;
  font-size: 12px;
}

.iCvr {
  filter: blur(3px);
  opacity: 0.5;
  z-index: -1;
  position: absolute;
  top: 0px;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover !important;
}
